
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '1671'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function repeatField3(field, fieldIndex) {
                    return _createElement.apply(this, [
                        'div',
                        {
                            'className': 'fitment-cell fitment-field-' + field.toLowerCase(),
                            'key': this[field]
                        },
                        this[field] && this[field] !== 'Universal' ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex)) : null
                    ]);
                }
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'fitment-row' },
                    _map(this.fields, repeatField3.bind(this))
                ]);
            }, { count: undefined })];
    }
    function repeatItems2(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '4201'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function repeatField3(field, fieldIndex) {
                    return [this[field] && this[field] !== 'Universal' ? _createElement('div', {
                            'className': 'fitment-row',
                            'key': this[field]
                        }, _createElement('div', { 'className': 'fitment-cell' }, window.themeSettings?.locales?.ymm[field.toLowerCase()] || field), _createElement.apply(this, [
                            'div',
                            { 'className': 'fitment-cell' },
                            _map(field === 'Year' ? this[field] : this[field].split('\n'), repeatFieldValue2.bind(this, field, fieldIndex))
                        ])) : null];
                }
                return itemsIndex !== 0 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'fitment-table__inner-container',
                        'key': '7'
                    },
                    _map(this.fields, repeatField3.bind(this))
                ]) : null;
            }, { count: undefined })];
    }
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'cm_fitment-table fitment-table-container',
        'key': '0'
    }, this.vehicleSpecific ? _createElement('div', {
        'className': 'fitment-table-title vehicle-specific',
        'key': '108'
    }) : null, this.manyFitments ? _createElement('div', {
        'className': 'fitment-table-title many-fitments',
        'key': '204'
    }, 'Currently displaying 1000 fitments, yet the product has additional fitments.') : null, this.template === 'horizontal' ? _createElement('div', {
        'className': 'cm_fitment-table__horizontal cmTemplate_horizontal',
        'key': '362'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null, this.template === 'vertical' ? _createElement('div', {
        'className': 'cm_fitment-table__vertical cmTemplate_vertical',
        'key': '1996'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems2.bind(this))
    ])) : null) : null;
}
        export const componentNames = []