const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = ['Engine', 'Gearbox', 'Drivetrain'];
const ignoreFields = ['Universal', 'Vehicle'];
const vehicleStore = globalThis.Convermax?.fitmentSearch?.vehicleStore;

const categorySelectionPageUrl = '/category-list/';
const brandSelectionPageUrl = '/brands/';

const isBushingsLanding = window.location.pathname.startsWith('/bushings');
window.Convermax.isBushingsLanding = isBushingsLanding;

function InitFunc() {
  const centered = window.document.querySelector('.page-content.page-content--centered');
  if (window.location.pathname === '/search/' && centered) {
    centered.classList = '';
  }

  if (vehicleStore) {
    document.querySelector('.page .page-sidebar')?.remove();
  }
}

function getLocalPreselection(pageType, defaults) {
  if (isBushingsLanding) {
    return [{ field: 'brand_name', term: 'Powerflex' }];
  }

  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  InitFunc,
  getLocalPreselection,
  defaultView: '3',
  SearchRequestDefaults: {
    pageSize: 36,
    selection:
      vehicleStore || isBushingsLanding
        ? [{ field: 'Vehicle', term: vehicleStore || window.Convermax?.categoryName }]
        : undefined,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
      { pathname: vehicleStore ? window.location.pathname : null, field: 'category' },
    ],
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl || vehicleStore ? 'parts' : defaults.getter(),
  },
  facets: {
    rangedFacet: { fields: ['price'], name: 'priceFacet' },
  },
  product: {
    noImageSrc: 'https://www.vivaperformance.com/product_images/uploaded_images/noimage.png',
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        selector: 'body.category #product-listing-container, body.brand #product-listing-container',
        class: 'cm_main-content',
      },
      template: 'MainContent',
      visibleIf: () => !vehicleStore,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
        'date_created:desc': 'Newest',
        'review_score:desc': 'Customer Review',
      },
      sortSelectClass: 'form-select',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetPanelCategory',
      type: 'SearchPage',
      location: {
        replace: 'body.category aside.page-sidebar, body.brand aside.page-sidebar',
        class: 'page-sidebar cm_FacetPanel__categories',
      },
      template: 'FacetPanelContainer',
      ignoreFields,
      visibleIf: () => !vehicleStore,
    },
    {
      name: 'SearchBox',
      location: '.navPages-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.headerSearch .container',
        class: 'container cm_search-box-root__dialog_open-button_container',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        replace: '#cm_vehicle-widget__stub',
        class: 'cm_vehicle-widget__header headerLower',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      fields: baseFitmentFields,
      columnBreakpoint: 800,
      useNativeDropdown: false,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: extraFitmentFields,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertAfter: 'body.product .form .form-field.form-field--increments',
      },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 800,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      useNativeDropdown: false,
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.tab-content#tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
      columnBreakpoint: 600,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        replace:
          'body.page-categoryList .page-content.page-content--centered, body.category #product-listing-container, body.brands main.page',
      },
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () =>
        window.location.pathname === categorySelectionPageUrl ||
        window.location.pathname === brandSelectionPageUrl ||
        !!vehicleStore,
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'Garage',
      location: {
        replace: '#cm_garage__stub',
        wrapper: 'li',
        class: 'navPages-item desktopOnly cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        insertBefore: '.mobileIcons a[href="/cart.php"]',
        wrapper: 'a',
        class: 'cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
