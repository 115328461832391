import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/vivaperformance/Templates/SearchPage.rt'
import TemplateMainContent from 'Stores/vivaperformance/Templates/MainContent.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/vivaperformance/Templates/FacetPanelContainer.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/vivaperformance/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/vivaperformance/Templates/fitmentSearch/fitmentTable.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialogVehicleSpecs from 'Stores/_default-store/Templates/FacetDialogVehicleSpecs.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
SearchResult,
FacetPanel,
SearchBox,
SearchBoxDialogButton,
VehicleWidget,
FitmentTable,
FacetTiles,
Garage,
FacetDialog,
SearchBoxDialog,
ContextDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'FacetDialogVehicleSpecs': TemplateFacetDialogVehicleSpecs,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};