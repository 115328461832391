
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function collapsibleVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2122'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '2328'
                    }, entry.hitCount) : null);
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2807'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3013'
                    }, entry.hitCount) : null);
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3473'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3679'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement('div', {
                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                    'key': '1247'
                }, !this.hideNullOption ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'onClick': () => this.onChange('')
                }, '\n      ', '', this.title, '\n    ') : null, this.loading ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'disabled': true
                }, '...loading...') : null, this.title === 'Make' && this.entries.some(entry => entry.payload === 'Popular') ? [
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '16761'
                    }, 'Popular Makes'),
                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry2.bind(this)),
                    ,
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '16766'
                    }, 'All Makes'),
                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry3.bind(this))
                ] : null, this.title !== 'Make' || !this.entries.some(entry => entry.payload === 'Popular') ? [!this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null] : null) : null);
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2122'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '2328'
                    }, entry.hitCount) : null);
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2807'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3013'
                    }, entry.hitCount) : null);
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3473'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3679'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement('div', {
                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                    'key': '1247'
                }, !this.hideNullOption ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'onClick': () => this.onChange('')
                }, '\n      ', '', this.title, '\n    ') : null, this.loading ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'disabled': true
                }, '...loading...') : null, this.title === 'Make' && this.entries.some(entry => entry.payload === 'Popular') ? [
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '16761'
                    }, 'Popular Makes'),
                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry2.bind(this)),
                    ,
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '16766'
                    }, 'All Makes'),
                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry3.bind(this))
                ] : null, this.title !== 'Make' || !this.entries.some(entry => entry.payload === 'Popular') ? [!this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null] : null) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home cm_vehicle-widget__header cm_vehicle-widget__collapsible' }, !this.withCollapsing ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns',
        'key': '116'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Shop By Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary cm_button-primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    GO\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button cm_button-secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle
    }, '\n    Clear\n  ')))) : null, this.withCollapsing ? _createElement('div', {
        'className': 'cm_vehicle-widget_header',
        'onClick': this.toggleCollapsed,
        'key': '7080'
    }, _createElement('span', { 'className': 'cm_vehicle-widget_header-label' }, !this.isVehicleSelected ? [
        'Shop By ',
        _createElement('span', { 'key': '72371' }, 'Vehicle')
    ] : null, this.isVehicleSelected ? [this.vehicleString] : null), !this.collapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle up',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'key': '74220'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, this.collapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle down',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'key': '77210'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null) : null, this.withCollapsing ? _createElement('div', {
        'className': 'cm_collapsible-elem',
        'key': '8028'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary cm_button-primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    GO\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button cm_button-secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle
    }, '\n    Clear\n  '))))) : null);
}
        export const componentNames = []