
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetPanelContainerRT () {
    return _createElement('div', { 'className': 'cm_FacetPanel cm_facet-panel__full-width page-sidebar' }, [this.FilterChips(function () {
            return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeDollarInchMm1_rangeChip() {
                                    var dollar = [
                                        'price',
                                        'Price'
                                    ].includes(this.field) ? '$' : '';
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '630' }, dollar, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '738' }, dollar, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '8760'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '4900'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'button cm_button-secondary cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Start over\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.VehicleSpecs(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '2122'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '2328'
                            }, entry.hitCount) : null);
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '2807'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3013'
                            }, entry.hitCount) : null);
                        }
                        function repeatEntry4(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3473'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3679'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled
                        }, this.useNativeDropdown ? [
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n          ', '', this.title, '\n        ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            _map(this.entries, repeatEntry1.bind(this))
                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                            'key': '_current',
                            'value': '_current'
                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement('div', {
                            'className': 'cm_dropdown cm_select_dropdown cm_hide',
                            'key': '1247'
                        }, !this.hideNullOption ? _createElement('div', {
                            'className': 'option',
                            'key': 'null-option',
                            'onClick': () => this.onChange('')
                        }, '\n      ', '', this.title, '\n    ') : null, this.loading ? _createElement('div', {
                            'className': 'option',
                            'key': 'null-option',
                            'disabled': true
                        }, '...loading...') : null, this.title === 'Make' && this.entries.some(entry => entry.payload === 'Popular') ? [
                            _createElement('div', {
                                'className': 'cm_option_title option',
                                'key': '16761'
                            }, 'Popular Makes'),
                            _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry2.bind(this)),
                            ,
                            _createElement('div', {
                                'className': 'cm_option_title option',
                                'key': '16766'
                            }, 'All Makes'),
                            _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry3.bind(this))
                        ] : null, this.title !== 'Make' || !this.entries.some(entry => entry.payload === 'Popular') ? [!this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null] : null) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_VehicleSpecs' }, this.selects.length ? _createElement('div', {
                'className': 'cm_vehicle-widget cm_vehicle-widget__specs',
                'key': '29'
            }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', { 'className': 'facettitle' }, 'Vehicle specs'), _createElement('div', { 'className': 'facetbody cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', {
                'className': 'button cm_button-secondary cm_filter-chips_button',
                'title': 'Reset',
                'onClick': this.discardExtraFields
            }, '\n        Reset\n      ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.Facets(function () {
            return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, '$', this.range[0], ' - $', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '592' }, '$', this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '668' }, '$', this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                function scopeDollarInchMm4() {
                                    var dollar = [
                                        'price',
                                        'Price'
                                    ].includes(this.field) ? '$' : '';
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '13256'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, dollar, this.min, inch, mm), _createElement('div', { 'className': 'max' }, dollar, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'className': 'form-input cm_inputMin',
                                                            'placeholder': 'Min'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '345'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'className': 'form-input cm_inputMax',
                                                            'placeholder': 'Max'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'button cm_button-secondary',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                    'className': 'facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-plus-minus',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 448 512',
                                        'key': '1650'
                                    }, _createElement('path', { 'd': 'M416,208H272V64c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32v144H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h144v144c0,17.67,14.33,32,32,32h32c17.67,0,32-14.33,32-32V304h144c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-plus-minus',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 448 512',
                                        'key': '5690'
                                    }, _createElement('path', { 'd': 'M416,208H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null), _createElement('div', {
                                    'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '1049'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '1135'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input form-input cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '1465'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '16341'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv cm_show-all-container',
                                    'key': '3465'
                                }, _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more',
                                    'tabIndex': '0'
                                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_selectFacet',
                                    'key': '3791'
                                }, [this.select(function () {
                                        function repeatEntry1(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                        }
                                        function repeatEntry2(entry, entryIndex) {
                                            return _createElement('div', {
                                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                                'key': entry.value,
                                                'onClick': () => this.onChange(entry.term)
                                            }, this.showCheckboxes ? _createElement('input', {
                                                'type': 'checkbox',
                                                'readOnly': true,
                                                'checked': entry.selected,
                                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                'key': '2145'
                                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                'className': 'cm_dropdown_hitCount',
                                                'key': '2351'
                                            }, entry.hitCount) : null);
                                        }
                                        function repeatEntry3(entry, entryIndex) {
                                            return _createElement('div', {
                                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                                'key': entry.value,
                                                'onClick': () => this.onChange(entry.term)
                                            }, this.showCheckboxes ? _createElement('input', {
                                                'type': 'checkbox',
                                                'readOnly': true,
                                                'checked': entry.selected,
                                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                'key': '2830'
                                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                'className': 'cm_dropdown_hitCount',
                                                'key': '3036'
                                            }, entry.hitCount) : null);
                                        }
                                        function repeatEntry4(entry, entryIndex) {
                                            return _createElement('div', {
                                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                                'key': entry.value,
                                                'onClick': () => this.onChange(entry.term)
                                            }, this.showCheckboxes ? _createElement('input', {
                                                'type': 'checkbox',
                                                'readOnly': true,
                                                'checked': entry.selected,
                                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                'key': '3496'
                                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                'className': 'cm_dropdown_hitCount',
                                                'key': '3702'
                                            }, entry.hitCount) : null);
                                        }
                                        return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                                            'className': 'cm_select_inner-select cm_select__pretty',
                                            'onChange': this.onChange,
                                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                            'disabled': this.disabled
                                        }, this.useNativeDropdown ? [
                                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                'value': '',
                                                'key': 'null-option'
                                            }, '\n          ', '', this.title, '\n        ') : null,
                                            this.loading ? _createElement('option', {
                                                'key': 'loading-option',
                                                'disabled': true
                                            }, '...loading...') : null,
                                            _map(this.entries, repeatEntry1.bind(this))
                                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                                            'key': '_current',
                                            'value': '_current'
                                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement('div', {
                                            'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                            'key': '1270'
                                        }, !this.hideNullOption ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'onClick': () => this.onChange('')
                                        }, '\n      ', '', this.title, '\n    ') : null, this.loading ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'disabled': true
                                        }, '...loading...') : null, this.title === 'Make' && this.entries.some(entry => entry.payload === 'Popular') ? [
                                            _createElement('div', {
                                                'className': 'cm_option_title option',
                                                'key': '16991'
                                            }, 'Popular Makes'),
                                            _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry2.bind(this)),
                                            ,
                                            _createElement('div', {
                                                'className': 'cm_option_title option',
                                                'key': '16996'
                                            }, 'All Makes'),
                                            _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry3.bind(this))
                                        ] : null, this.title !== 'Make' || !this.entries.some(entry => entry.payload === 'Popular') ? [!this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null] : null) : null));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '10229'
                                }, !this.ranges.length ? [
                                    _createElement('div', { 'key': '103131' }, '$', this.selectedRange[0], ' to $', this.selectedRange[1]),
                                    '\n  ',
                                    this.slider,
                                    '\n  ',
                                    _createElement('div', {
                                        'className': 'cm_flex',
                                        'key': '103133'
                                    }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                ] : null, this.ranges.length ? [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cmRepeater_Values',
                                            'key': '105891'
                                        },
                                        _map(this.Values, repeatValues3.bind(this))
                                    ])] : null, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'className': 'form-input cm_inputMin',
                                                    'placeholder': 'Min price'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'className': 'form-input cm_inputMax',
                                                    'placeholder': 'Max price'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('button', {
                                            'type': 'button',
                                            'className': 'button cm_button-secondary',
                                            'onClick': this.setCustomRange
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_reviewFacet',
                                    'key': '15187'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues6.bind(this))
                                ])) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterChips","cm:FilterChips","cm:VehicleSpecs","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets"]