//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-392:_3592,_4588,_552,_4708,_3768,_8213,_2652,_6008;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-392')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-392', "_3592,_4588,_552,_4708,_3768,_8213,_2652,_6008");
        }
      }catch (ex) {
        console.error(ex);
      }